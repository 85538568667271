import React from "react"
import { graphql } from "gatsby"
import SEO from "../../components/seo"
import Layout from "../../components/layout"

const ImprintPage = ({ data }) => {
  return (
    <Layout title="Imprint">
      <SEO title="Imprint | Leonie Freudenberger" />
      <article className="tabbed-content">
        <div
          dangerouslySetInnerHTML={{
            __html: data.contentfulAllgemein.imprint.childMarkdownRemark.html,
          }}
        ></div>
      </article>
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulAllgemein(id: { eq: "a184f87a-8247-5ceb-a1ea-8811849afd31" }) {
      imprint {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default ImprintPage
